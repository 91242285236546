export function createSingleObserver(el:HTMLImageElement) {
    function loadImage() {
            el.addEventListener("load", () => {
                setTimeout(() => el.classList.add("loaded"), 100);
            });
            el.addEventListener("error", (event) => {
                if(el.classList.contains("deleteOnError")) {
                    el.remove();
                }
            });
            el.src = el.dataset.src as string;
    }

    function handleIntersect(entries, observer) {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                loadImage();
                observer.unobserve(el);
            }
        });
        observer = null;
    }

    function createObserver() {
        const options = {
            root: null,
            threshold: 0
        } as IntersectionObserverInit;
        const observer = new IntersectionObserver(handleIntersect, options);
        observer.observe(el);
    }

    if (window["IntersectionObserver"]) {
        createObserver();
    } else {
        loadImage();
    }
}

